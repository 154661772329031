import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
const config = {
    max: 6
}

const cdn = (fileName) => {
    return `https://aarvalabs.clodui.com/${fileName}`
}

const TripleAContainer = styled.div`
    .standardWidth {
        width: 400px;

        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }

    .topbutton {
        cursor: pointer;
        border: 1px solid black;
        padding: 8px;
        display: inline-block;
        margin: 8px;
        font-size: 14px;
        font-weight: 500;
        color: inherit;
        text-decoration: none;
    }

    .extraWidth {
        height: 80vh;

        @media screen and (max-width:600px) {
            width: 90%
        }
    }
`

const Intro = () => {
    return (
        <TripleAContainer>
            <div style={{ textAlign: "center" }}>
                <Link className="topbutton" to={`/comic/${config.max}`}>Comic</Link>
                <h3>Portside Diaries</h3>
                <p>Probably Real conversations</p>
                <Link to={`/comic/${config.max}`}>
                    <video className="extraWidth" src={cdn(`triplea/portsidediaries.mp4`)} autoPlay playsInline muted /><br />
                </Link>
            </div>
        </TripleAContainer>
    )
}

const ComicImg = styled.img`
    margin: 8px;
    border: 5px solid;
`
class Comic extends Component {
    handleRightArrow() {
        const { id } = (this.props.match || {}).params || {}
        const comicNumber = parseInt(id)
        if (!id || comicNumber === config.max) return;
        this.props.history.push(`/comic/${comicNumber + 1}`)
    }

    handleLeftArrow() {
        const { id } = (this.props.match || {}).params || {}
        const comicNumber = parseInt(id)
        if (!id || comicNumber === 1) return;
        this.props.history.push(`/comic/${comicNumber - 1}`)
    }
    componentDidMount() {
        document.addEventListener("keyup", e => {
            if (e.keyCode === 37) { this.handleLeftArrow(); return; }
            if (e.keyCode === 39) { this.handleRightArrow(); return; }
        })
    }

    render() {
        const { id } = this.props.match.params
        return (
            <TripleAContainer>
                <div style={{ width: '100%', textAlign: "center" }}>
                    <Link className="topbutton" to="/">Home</Link>
                    <Bar id={id} />
                    <div style={{ margin: "0 auto", textAlign: 'center', display: "flex", flexWrap: "wrap", maxWidth: "855px" }}>
                        <div><ComicImg className="standardWidth" alt="A" src={cdn(`triplea/${id}/A.png`)} ></ComicImg></div>
                        <div><ComicImg className="standardWidth" alt="B" src={cdn(`triplea/${id}/B.png`)} ></ComicImg></div>
                        <div><ComicImg className="standardWidth" alt="C" src={cdn(`triplea/${id}/C.png`)} ></ComicImg></div>
                        <div><ComicImg className="standardWidth" alt="D" src={cdn(`triplea/${id}/D.png`)} ></ComicImg></div>
                    </div>
                    <Bar id={id} />
                </div >
            </TripleAContainer>
        )
    }
}

const ComicWithRouter = withRouter(Comic)

const PrevNextDiv = styled(Link)`
    visibility: ${props => props.visibility};
    display: inline;
    border: 1px solid;
    cursor: pointer;
    padding: 8px;
    margin: 8px;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
    text-decoration: none;   
`

const Bar = ({ id }) => {
    const comicNumber = parseInt(id)
    const prevVisibility = comicNumber === 1 ? "hidden" : "visible"
    const nextVisibility = comicNumber === config.max ? "hidden" : "visible"
    return (<div style={{ margin: "10px" }}>
        <PrevNextDiv to={`/comic/${comicNumber - 1}`} visibility={prevVisibility}> {"<-"} Prev</PrevNextDiv>
        {id}
        <PrevNextDiv to={`/comic/${comicNumber + 1}`} visibility={nextVisibility}>Next {"->"}</PrevNextDiv>
    </div>)
}

export { Intro, ComicWithRouter as Comic }
import React from 'react';
import './App.css';
import { Intro, Comic } from './TripleA'
import { Switch, Route, BrowserRouter } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>

  );
}

const Main = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Intro}></Route>
        <Route path="/comic/:id" component={Comic}></Route>
      </Switch>
    </main>
  )
}

export default App;
